<template>
  <div>
    <div class="row mx-0 bg-light">
      <div class="left col-md d-flex py-3 p-sm-5">
        <div class="container content" style="width: 100%">
          <h1 class="mb-3 mb-md-5">
            <router-link to="/login">
              <img src="/img/login-logo.png" :alt="`${tenant_name}`" />
            </router-link>
          </h1>

        <router-view></router-view>

        <footer class="small text-center">
          <nav>
            <a class="font-weight-medium" v-if="privacyPolicy.show" :href="privacyPolicy.url">{{ privacyPolicy.text }}</a>
            <span class="mr-2 ml-2" v-if="privacyPolicy.show && (termsOfUse.show || websiteInfo.show)">|</span>
            <a class="font-weight-medium" v-if="termsOfUse.show" :href="termsOfUse.url">{{ termsOfUse.text }}</a>
            <span class="mr-2 ml-2" v-if="termsOfUse.show && websiteInfo.show">|</span>
            <a class="font-weight-medium" v-if="contactInfo.show" :href="contactInfo.url">{{ contactInfo.text }}</a>
          </nav>
          <a class="font-weight-medium" v-if="websiteInfo.show" :href="websiteInfo.url">{{ websiteInfo.text }}</a>
          <p class="view-login__login-footer__version">Version {{ version }} &#8226; Copyright &copy; {{ copyrightYear }}</p>
        </footer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "public-sign-up",
  data: function() {
    return {
      version: process.env.VERSION,
      privacyPolicy: {
        show: (process.env.VUE_APP_TEMPLATE_LOGIN_PRIVACY_POLICY_SHOW || '').toUpperCase() === 'TRUE',
        url: process.env.VUE_APP_TEMPLATE_LOGIN_PRIVACY_POLICY_URL,
        text: process.env.VUE_APP_TEMPLATE_LOGIN_PRIVACY_POLICY_TEXT
      },
      termsOfUse: {
        show: (process.env.VUE_APP_TEMPLATE_LOGIN_TERMS_OF_USE_SHOW || '').toUpperCase() === 'TRUE',
        url: process.env.VUE_APP_TEMPLATE_LOGIN_TERMS_OF_USE_URL,
        text: process.env.VUE_APP_TEMPLATE_LOGIN_TERMS_OF_USE_TEXT
      },
      contactInfo: {
        show: (process.env.VUE_APP_TEMPLATE_LOGIN_CONTACT_SHOW || '').toUpperCase() === 'TRUE',
        url: process.env.VUE_APP_TEMPLATE_LOGIN_CONTACT_URL,
        text: process.env.VUE_APP_TEMPLATE_LOGIN_CONTACT_TEXT
      },
      websiteInfo: {
        show: (process.env.VUE_APP_TEMPLATE_LOGIN_WEBSITE_SHOW || '').toUpperCase() === 'TRUE',
        url: process.env.VUE_APP_TEMPLATE_LOGIN_WEBSITE_URL,
        text: process.env.VUE_APP_TEMPLATE_LOGIN_WEBSITE_TEXT
      },
      blurb: process.env.VUE_APP_TEMPLATE_LOGIN_BLURB,
      tenant_name: process.env.VUE_APP_TENANT_NAME
    };
  },
  computed: {
    copyrightYear () {
      return new Date().getFullYear();
    }
  },
  methods: {
  }
};
</script>

<style scoped>
.component-fade-enter-active,
.component-fade-leave-active {
  transition: opacity 0.3s ease;
}
.component-fade-enter,
.component-fade-leave-to {
  opacity: 0;
}
</style>
